
.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%,#ffffff);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
    animation: spinner-zp9dbg 1s infinite linear;
 }
 
 @keyframes spinner-zp9dbg {
    to {
       transform: rotate(1turn);
    }
 }
.op0,
    .op0 h3 {
        visibility: hidden;
    }

    .op0 ul,
    .op0 ul:hover,
    .op0 img,
    .op0:hover roadMap__steps-img {
        opacity: 0 !important;
    }

    div.presale_content {
        position: absolute;
        top: 15%;
        width: 100%;
    }

    .presale_content .card {
        margin: 0 auto;
        width: auto !important;
    }

    .ok:before {
        content: "";
        position: absolute;
        left: -63px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 0 20px 18px;
        border-color: transparent transparent transparent var(--tg-theme-primary);
        opacity: 1;
        transform: rotate(180deg);
    }

    .toplanan_para {
        width: 100%;
        max-width: 100%;
        padding: 50px;
    }

    @media screen and (max-width: 1000px) {

        h6,
        h1 {
            font-size: 20px !important;
        }
    }

    .tank {
        animation-name: tank;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        width: 42px;
        margin-left: 25%;
        position: absolute;
    }

    @keyframes tank {
        0% {
            left: 0px;
        }

        25% {
            left: 2px;
        }

        50% {
            left: 4px;
        }

        75% {
            left: 2px;
        }

        100% {
            left: 0px;
        }
    }

    .modalx {
        z-index: 9999;
        position: fixed;
        margin-top: 25%;
        margin-left: 50%;
        background-image: url(../assets/images/background/shop_shape.svg);
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
    }

    .shadow {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: #000000a3;
    }

    #change_pwd {
        color: var(--tg-theme-button);
    }

    .showx {
        opacity: 1;
    }

    #pwd_post_item {
        width: 80%;
    }

    @media screen and (min-width: 1500px) {
        #pwd_post_item {
            width: 50%;
            margin-left: 10%;
        }
    }


    .cd-timeline-block,
    .cd-timeline-content {
        border-bottom-right-radius: 50px !important;

    }

    .form-control:focus {
        background-color: #2a3f50 !important;
    }