.contact-area {
    padding: 140px 0 125px;
}

.contact__content {
    margin-right: 50px;
    position: relative;
    z-index: 1;
}

@media (max-width: 1500px) {
    .contact__content {
        margin-right: 0;
    }
}

.contact__content .overlay-title {
    font-size: 90px;
    line-height: 0.8;
    font-weight: var(--tg-fw-extra-bold);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-transform: translateY(calc(-100% + 13px));
    -moz-transform: translateY(calc(-100% + 13px));
    -ms-transform: translateY(calc(-100% + 13px));
    -o-transform: translateY(calc(-100% + 13px));
    transform: translateY(calc(-100% + 13px));
    margin: 0 0;
    letter-spacing: 4px;
    z-index: -1;
}

@media (max-width: 767.98px) {
    .contact__content .overlay-title {
        font-size: 16vw;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .contact__content .overlay-title {
        font-size: 13vw;
    }
}

.contact__content .overlay-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 100%;
    background: rgb(15, 22, 27);
    background: linear-gradient(0deg, rgb(15, 22, 27) 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.65;
}

.contact__content .overlay-title span {
    display: block;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--tg-common-color-white);
    opacity: 0.05;
}

.contact__content>.title {
    font-size: 45px;
    font-weight: var(--tg-fw-extra-bold);
    margin: 0 0 21px;
}

@media (max-width: 767.98px) {
    .contact__content>.title {
        font-size: 40px;
    }
}

.contact__content p {
    font-weight: var(--tg-fw-medium);
    margin: 0 0 0;
}

.contact__content .footer-el-widget {
    margin: 30px 0 0 !important;
}

@media (max-width: 991.98px) {
    .contact__form-wrap {
        margin: 50px 0 0;
    }
}

.contact__form-wrap .input-grp {
    position: relative;
    -webkit-clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
    margin: 0 0 30px;
}

.contact__form-wrap .input-grp::after {
    content: "";
    position: absolute;
    background-color: #262f39;
    width: 60px;
    height: 1px;
    right: -21px;
    bottom: 12px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.contact__form-wrap .input-grp input,
.contact__form-wrap .input-grp textarea {
    display: block;
    width: 100%;
    border: 1px solid #262f39;
    background: transparent;
    color: var(--tg-common-color-white);
    padding: 15px 25px;
}

.contact__form-wrap .input-grp textarea {
    height: 148px;
    max-height: 148px;
}

.contact__form-wrap .input-grp.message-grp {
    margin: 0 0 35px;
}

.contact__form-wrap .submit-btn {
    -webkit-clip-path: polygon(100% 0, 100% 65%, 89% 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 100% 65%, 89% 100%, 0 100%, 0 0);
    background: var(--tg-theme-primary);
    color: var(--tg-common-color-black);
    font-family: var(--tg-heading-font-family);
    text-transform: uppercase;
    font-weight: var(--tg-fw-bold);
    letter-spacing: 1px;
    border: none;
    padding: 14px 30px;
}

.contact__form-wrap .submit-btn:hover {
    background: var(--tg-theme-secondary);
}

.contact__form-wrap .ajax-response {
    margin: 0 0;
}

.contact__form-wrap .ajax-response.error,
.contact__form-wrap .ajax-response.success {
    margin-top: 15px;
}

.contact__form-wrap .ajax-response.error {
    color: var(--tg-theme-secondary);
}

.contact__form-wrap .ajax-response.success {
    color: var(--tg-theme-primary);
}

.contact-map iframe {
    display: block;
    width: 100%;
    height: 600px;
    opacity: 0.8;
}

@media (max-width: 1500px) {
    .contact-map iframe {
        height: 450px;
    }
}

.pagination__wrap {
    margin: 20px 0 0;
}

.pagination__wrap ul {
    gap: 10px 15px;
}

@media (max-width: 767.98px) {
    .pagination__wrap ul {
        gap: 10px;
    }
}