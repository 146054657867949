@use '../scss/' as *;
.join_community {
    position: relative;
}
.join_community:before {
    position: absolute;
    content: ' ';
    opacity: 0.3;
    width: 100%;
    z-index: -1;
    height: 100%;
    background-image: url(../assets/images/background/dna_like.png);
    background-size: 100%;
    background-attachment: fixed;
    background-position: center;
}