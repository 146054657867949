@use '../../scss/' as *;

.box-text {
    position: relative;
    background: $bg-4;
    transform: translateY(0);

    &.active,
    &:hover {
        box-shadow: $box-shadow;
    }

    .h7 {
        text-transform: capitalize;
        margin-bottom: 16px;
    }

    p {
        color: $color-main1;
        margin-bottom: 0;
    }

    .number {
        position: absolute;
        top: -2px;
        left: 18px;
        font-size: 80px;
        line-height: 96px;
        color: rgba(34, 183, 143, 0.1);
        font-weight: 700;
        font-family: $font-main;
    }

    @include tablet {
        margin-bottom: 30px;
    }
}

.home-2 .box-text,
.home-3 .box-text {
    margin-bottom: 30px;
}




//ESKISITE
.nft-item__box {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #121a23;
    background-image: -moz-linear-gradient(90deg, #0c0e12 0%, rgba(31, 41, 53, 0.36078) 100%);
    background-image: -webkit-linear-gradient(90deg, #0c0e12 0%, rgba(31, 41, 53, 0.36078) 100%);
    background-image: -ms-linear-gradient(90deg, #0c0e12 0%, rgba(31, 41, 53, 0.36078) 100%);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -o-border-radius: 8px;
    -ms-border-radius: 8px;
    border-radius: 8px;
    border: 1px solid rgba(76, 76, 76, 0.2);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media (max-width: 767.98px) {
    .nft-item__box {
        display: block;
        text-align: center;
        max-width: 320px;
        padding: 35px 25px 25px;
        margin: 0 auto 30px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .nft-item__box {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        text-align: left;
        max-width: 100%;
        padding: 25px 25px;
        margin: 0 0 30px;
    }
}

.nft-item__box:hover {
    border-color: rgba(69, 248, 130, 0.2);
}

.nft-item__thumb {
    flex: 0 0 auto;
    margin-right: 20px;
    max-width: 187px;
}

@media (max-width: 1800px),
(max-width:1940px) {
    .nft-item__thumb {
        max-width: 165px;
    }

}

/* @media (max-width: 1940px) {
   .nft-item__thumb {
       max-width: 165px;
   }
} */

@media (max-width: 1500px) {
    .nft-item__thumb {
        max-width: 170px;
    }

    .nft-item__box .name {
        margin: 0 0 0px 0;
    }
}

@media (max-width: 991.98px) {
    .nft-item__thumb {
        max-width: 187px;
    }
}

@media (max-width:1401.545px) {
    .nft-item__thumb img {

        max-width: 100px;
        height: auto;
    }
}

@media (max-width: 767.98px) {
    .nft-item__thumb {
        margin: 0 auto 25px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .nft-item__thumb {
        margin: 0 20px 0 0;
    }
}

.nft-item__thumb img {
    width: 120px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -o-border-radius: 8px;
    -ms-border-radius: 8px;
    border-radius: 8px;
}

.nft-item__content {
    flex-grow: 1;
}

.nft-item__content .title {
    font-size: 18px;
    letter-spacing: 0.8px;
    margin: 0 0 9px;
}

.nft-item__avatar {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin: 0 0 20px;
}

@media (max-width: 767.98px) {
    .nft-item__avatar {
        justify-content: center;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .nft-item__avatar {
        justify-content: flex-start;
    }
}

.nft-item__avatar .avatar-img {
    margin: 0 10px 0 0;
    flex: 0 0 auto;
}

.nft-item__avatar .avatar-img img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.nft-item__avatar .avatar-name {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}

.nft-item__avatar .avatar-name .name {
    margin: 0 0 0;
    font-size: 18px;
    font-weight: var(--tg-fw-semi-bold);
    color: #9b9b9b;
    text-transform: capitalize;
}

.nft-item__avatar .avatar-name .designation {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: #9b9b9b;
}

.nft-item__avatar .avatar-name .designation::before {
    content: "|";
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: #434343;
    padding: 0 8px 0 10px;
}

.nft-item__price {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flow-root;
    align-items: center;
    background-color: #121a23;
    background-image: -moz-linear-gradient(90deg, rgb(12, 14, 18) 0%, rgb(16, 24, 31) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(12, 14, 18) 0%, rgb(16, 24, 31) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(12, 14, 18) 0%, rgb(16, 24, 31) 100%);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -o-border-radius: 8px;
    -ms-border-radius: 8px;
    border-radius: 1px;
    /* border: 1px solid rgba(76, 76, 76, 0.5); */
    padding: 1px 1px 1px 0;
    /* first:top, second:right, third:bottom, fourth:left */
}

.nft-item__price p {
    margin: 0 0;
    font-family: var(--tg-heading-font-family);
    font-size: 16px;
    color: #e8e8e8;
    font-weight: var(--tg-fw-semi-bold);
}

.nft-item__price p span {
    text-transform: uppercase;
    font-weight: var(--tg-fw-bold);
    color: var(--tg-theme-secondary);
}

.nft-item__price .bid-btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    column-gap: 10px;
    background: var(--tg-theme-secondary);
    color: var(--tg-common-color-black-2);
    text-transform: capitalize;
    font-family: var(--tg-heading-font-family);
    font-weight: var(--tg-fw-semi-bold);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -o-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 1;
    padding: 11px 14px;
    margin-left: auto;
}

.nft-item__price .bid-btn:hover {
    background: var(--tg-theme-primary);
}