.contact {
    section {
        background-repeat: 'no-repeat';
        background-attachment: "fixed";
        background-size: 'cover';
        padding: 5%;
    }
}

h2.title {
    text-align: center;
    color: black;
    font-weight: bold;
}

#message{
    background-color: transparent;
}


#message::placeholder{
    color:rgba(255, 255, 255, 0.666);
}

#send_message{
    width: 100%;
}

input, textarea{
    color: white !important;
}