video.slider {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

.viewport-header {
    position: relative;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.maf-token{
    z-index: 999;
    padding: 15px !important;
    margin: 20px !important;
    text-align: center;
    align-items: center;
    margin-left: -5px !important;
    color:var(--tg-border-4) !important;
}

.login, .register {
    display: none !important;
}

@media screen and (max-width: 1200px){
    .maf-token{
        padding: 5px !important;
        width: 10%;
        align-items: left;
        height: 30px;
        left: 0;
        margin: auto !important;
    }
    .maf-li a{
        background-color: #edc9af;
        color: #ffffff !important;
    }

    .login, .register{
        display: block !important;
        background-color: #edc9af;
        color: #ffffff !important;
    }


}

.maf-token:hover .maf-li{
    background: var(--tg-common-color-gray);
    color: var(--tg-common-color-white) !important;
}

.hidden {
    display: none !important;
}


.slider__content {
    margin: 135px 0 0 40px;
    position: relative;
    z-index: 2;
}

@media (max-width: 1800px) {
    .slider__content {
        margin: 135px 0 0 0;
    }
}

@media (max-width: 1500px) {
    .slider__content {
        margin: 90px 0 0 0;
    }
}

@media (max-width: 991.98px) {
    .slider__content {
        margin: 0 0 70px;
        text-align: center;
    }
}

@media (max-width: 767.98px) {
    .slider__content {
        margin: 0 0 50px;
    }
}

.slider__content .sub-title {
    margin: 0 0 19px;
    line-height: 1;
    font-family: var(--tg-body-font-family);
    font-weight: var(--tg-fw-bold);
    letter-spacing: 4px;
    font-size: 20px;
    color: #b4ab09;
    position: relative;
    padding: 17px 0 17px 20px;
}

@media (max-width: 1199.98px) {
    .slider__content .sub-title {
        font-size: 16px;
        padding: 15px 0 15px 20px;
    }
}

@media (max-width: 991.98px) {
    .slider__content .sub-title {
        font-size: 18px;
        padding: 15px 20px 15px;
    }
}

@media (max-width: 767.98px) {
    .slider__content .sub-title {
        font-size: 17px;
        padding: 15px 20px 15px;
    }
}

.slider__content .sub-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(../../assets/images/background/text_gradient_bg.png);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 0.28;
}

@media (max-width: 1199.98px) {
    .slider__content .sub-title::before {
        background-size: contain;
    }
}

@media (max-width: 991.98px) {
    .slider__content .sub-title::before {
        left: 1px;
        top: 0;
        right: 0;
        width: 297px;
        margin: 0 auto;
    }
}

@media (max-width: 767.98px) {
    .slider__content .sub-title::before {
        left: -30px;
        width: 256px;
        margin: 0 auto;
    }
}

.slider__content .title {
    margin: 0 0 21px;
    font-size: 150px;
    font-family: var(--tg-berlin-font-family);
    line-height: 0.8;
    text-shadow: -1px 5px 0px rgba(69, 248, 130, 0.66);
}

@media (max-width: 1500px) {
    .slider__content .title {
        font-size: 118px;
    }
}

@media (max-width: 1199.98px) {
    .slider__content .title {
        font-size: 94px;
    }
}

@media (max-width: 991.98px) {
    .slider__content .title {
        font-size: 120px;
    }
}

@media (max-width: 767.98px) {
    .slider__content .title {
        font-size: 18vw;
        text-shadow: -1px 3px 0px rgba(69, 248, 130, 0.66);
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider__content .title {
        font-size: 17vw;
        text-shadow: -1px 5px 0px rgba(69, 248, 130, 0.66);
    }
}

.slider__content p {
    margin: 0 0;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 5px;
    line-height: 1.4;
    color: var(--tg-common-color-white);
}

@media (max-width: 1199.98px) {
    .slider__content p {
        font-size: 22px;
    }
}

@media (max-width: 991.98px) {
    .slider__content p {
        font-size: 24px;
    }
}

@media (max-width: 767.98px) {
    .slider__content p {
        font-size: 20px;
        letter-spacing: 3px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider__content p {
        font-size: 24px;
        letter-spacing: 5px;
    }
}

.slider__btn {
    margin: 33px 0 0;
}

.slider__btn .tg-btn-1 {
    padding: 15px 49px;
}

.slider__img img {
    margin: 0 auto;
}

@media (max-width: 1199.98px) {
    .slider__img img {
        margin: 0 0 0 auto;
        max-width: 90%;
        display: block;
    }
}

@media (max-width: 991.98px) {
    .slider__img img {
        margin: 0 auto;
        max-width: 480px;
        width: 100%;
        display: block;
    }
}

.slider__shapes {
    opacity: 0.27;
}

.slider__shapes img {
    position: absolute;
    animation: shapeBlinker 1.5s linear infinite;
    opacity: 0;
    z-index: -1;
}

.slider__shapes img:nth-child(1) {
    left: 23%;
    top: 17%;
    animation-delay: 0.2s;
}

@media (max-width: 991.98px) {
    .slider__shapes img:nth-child(1) {
        left: 13%;
        top: 12%;
    }
}

@media (max-width: 767.98px) {
    .slider__shapes img:nth-child(1) {
        left: 11%;
        top: 11%;
    }
}

.slider__shapes img:nth-child(2) {
    left: 29%;
    bottom: 45%;
    animation-delay: 0.4s;
}

@media (max-width: 991.98px) {
    .slider__shapes img:nth-child(2) {
        left: 12%;
    }
}

@media (max-width: 767.98px) {
    .slider__shapes img:nth-child(2) {
        left: 10%;
        bottom: 55%;
    }
}

.slider__shapes img:nth-child(3) {
    right: 47%;
    top: 20%;
    animation-delay: 0.2s;
}

@media (max-width: 991.98px) {
    .slider__shapes img:nth-child(3) {
        right: 23%;
        top: 15%;
    }
}

@media (max-width: 767.98px) {
    .slider__shapes img:nth-child(3) {
        right: 10%;
        top: 17%;
    }
}

.slider__shapes img:nth-child(4) {
    right: 42%;
    top: 23%;
    animation-delay: 0.6s;
}

@media (max-width: 1500px) {
    .slider__shapes img:nth-child(4) {
        right: 40%;
        top: 28%;
    }
}

@media (max-width: 991.98px) {
    .slider__shapes img:nth-child(4) {
        right: 20%;
        top: 35%;
    }
}

@media (max-width: 767.98px) {
    .slider__shapes img:nth-child(4) {
        right: 14%;
        top: 33%;
    }
}

.slider__brand-wrap {
    position: absolute;
    left: 0;
    bottom: 130px;
    width: 100%;
    height: auto;
}

@media (max-width: 1500px) {
    .slider__brand-wrap {
        bottom: 150px;
    }
}

@media (max-width: 1199.98px) {
    .slider__brand-wrap {
        bottom: 85px;
    }
}

@media (max-width: 991.98px) {
    .slider__brand-wrap {
        bottom: 0;
        position: relative;
    }
}

.slider__brand-list {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    align-items: center;
    gap: 20px 60px;
    margin: 0 0 0 40px;
}

@media (max-width: 1800px) {
    .slider__brand-list {
        margin: 0 0 0;
    }
}

@media (max-width: 1199.98px) {
    .slider__brand-list {
        width: 60%;
        gap: 20px 30px;
    }
}

@media (max-width: 991.98px) {
    .slider__brand-list {
        width: 100%;
        justify-content: center;
    }
}

@media (max-width: 767.98px) {
    .slider__brand-list {
        flex-wrap: nowrap;
        gap: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider__brand-list {
        gap: 35px;
    }
}

.slider__brand-list li a {
    display: block;
}

.slider__area {
    position: relative;
}

.slider__area::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    background-color: #b4ab09;
    height: 50px;
}

@media (max-width: 1500px) {

    .slider__area::before,
    .slider__area::after {
        height: 40px;
    }
}

@media (max-width: 1199.98px) {

    .slider__area::before,
    .slider__area::after {
        height: 30px;
    }
}

@media (max-width: 767.98px) {

    .slider__area::before,
    .slider__area::after {
        height: 20px;
    }
}

.slider__area::after {
    left: auto;
    right: 0;
    -webkit-clip-path: polygon(100% 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
}
.justify-content-between {
    justify-content: space-between !important;
    position: absolute;
    bottom: 25%;
}