.services__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px 50px;
}

.about {
    section {
        background-repeat: 'no-repeat';
        background-attachment: "fixed";
        background-size: 'cover';
        background-position: 'top';
    }
}

@media (max-width: 1199.98px) {
    .services__wrapper {
        gap: 50px 45px;
    }
}

@media (max-width: 767.98px) {
    .services__wrapper {
        text-align: center;
        grid-template-columns: repeat(1, 1fr);
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .services__wrapper {
        gap: 50px 40px;
        grid-template-columns: repeat(2, 1fr);
    }
}

.services__icon {
    font-size: 40px;
    display: block;
    line-height: 1;
    color: #b4ab09;
    margin: 0 0 30px;
}

.services__content .title {
    font-size: 20px;
    font-weight: var(--tg-fw-semi-bold);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    margin: 0 0 18px;
}

.services__content .title a:hover {
    color: #b4ab09;
}

.services__content p {
    font-size: 15px;
    font-weight: var(--tg-fw-medium);
    margin: 0 0;
}

.services__images {
    margin: 0 0 30px 36px;
    position: relative;
    height: 681px;
}

@media (max-width: 1199.98px) {
    .services__images {
        margin: 0 0 30px 20px;
        height: 490px;
    }
}

@media (max-width: 991.98px) {
    .services__images {
        margin: 70px 0 30px 0;
    }
}

@media (max-width: 767.98px) {
    .services__images {
        height: 360px;
    }
}

.services__images-item {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: all 0.1s ease-in-out;
    z-index: 1;
}

.services__images-item img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    clip-path: inset(0 100% 0 0);
    -webkit-animation: defaultInset 0.8s forwards ease;
    animation: defaultInset 0.8s forwards ease;
}

.services__images-item.active {
    z-index: 2;
}

.services__images-item.active img {
    -webkit-animation: activeInset 0.8s forwards ease;
    animation: activeInset 0.8s forwards ease;
}

.services__images-item.active .services__link i {
    animation: tg_arrow 0.8s forwards;
}

.services__link {
    position: absolute;
    right: -30px;
    bottom: -30px;
    width: 153px;
    height: 153px;
    font-size: 98px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #faa706;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    color: #000;
    overflow: hidden;
}

@media (max-width: 1199.98px) {
    .services__link {
        right: 0;
        bottom: -30px;
        width: 120px;
        height: 120px;
        font-size: 70px;
    }
}

@media (max-width: 767.98px) {
    .services__link {
        width: 80px;
        height: 80px;
        font-size: 45px;
    }
}

.services__link:hover {
    color: #000;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}

.services__details-area {
    background-position: top center;
    background-size: cover;
}